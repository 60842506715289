import request from '@/utils/request'

// 申请合伙人界面 - 钱包接口
export function getWalletInfo(params) {
    return request.get(`/api/collaborate/getWalletInfo`, { params })
}


// 综合全体统计
export function statistics(params) {
    return request.get(`/api/collaborate/statistics`, { params })
}

// 首页模板 当日收益接口
export function priceIndex(params) {
    return request.get(`/api/collaborate/priceIndex`, { params })
}

// 首页模板 当月新增团队人数接口
export function userIndex(params) {
    return request.get(`/api/collaborate/userIndex`, { params })
}

// 收益明细  收益明细列表
export function priceLog(params) {
    return request.get(`/api/collaborate/priceLog`, { params })
}

// 订单查看  订单列表
export function orderList(params) {
    return request.get(`/api/collaborate/order`, { params })
}

// 订单查看  订单列表  订单详情
export function orderDetail(params) {
    return request.get(`/api/collaborate/orderDetail`, { params })
}

// 团队人数 团队管理统计
export function userStatistics(params) {
    return request.get(`/api/collaborate/userStatistics`, { params })
}

// 团队人数 团队列表
export function userGetTeamUser(params) {
    return request.get(`/api/collaborate/getTeamUser`, { params })
}

// 团队人数 团队列表 --> 团队详情
export function userGetTeamUserid(params) {
    return request.get(`/api/collaborate/getTeamUserShow`, { params })
}


// 用户管理 同伙消息管理
export function getInformation(params) {
    return request.get(`/api/collaborate/getInformation`, { params })
}

// 用户管理 同伙消息管理  消息详情
export function getInformationShow(params) {
    return request.get(`/api/collaborate/getInformationShow`, { params })
}


//-------------提现
// 获取实名认证名称
export function getUserInfo(params) {
    return request.get(`/api/collaborate/getUserInfo`, { params })
}

// 获取银行卡列表
export function getBank(params) {
    return request.get(`/api/collaborate/getBank`, { params })
}

// 提取金额
export function getExtract(data) {
    return request.post(`/api/collaborate/withdraw_price`, data)
}

// 提现明细
export function getWithdrawal(params) {
    return request.get(`/api/collaborate/getWithdrawal`, { params })
}


// 团队管理统计
export function getCountStatistics(params) {
    return request.get(`/api/collaborate/getCountStatistics`, { params })
}

// 去提现手续费
export function getWithrawRate(params) {
    return request.get(`/api/new_wallet/getSystemValue`, { params })
}

//------------------------
export function getdepositMoney(params) {
    return request.get(`/api/collaborate/getdepositMoney`, { params })
}

// 多笔订单退款
export function postdepositMoneyWithrawById(data) {
    return request.post(`/api/collaborate/depositMoneyWithrawById`, data)
}

export function getnew_collaborate_Bank(params) {
    return request.get(`/api/collaborate/getBank`, { params })
}
//--------默认可添加列表
export function getAllBankName(params) {
    return request.get(`/api/new_wallet/getAllBankName`, { params })
}

export function postnew_collaborate_bankNew(data) {
    return request.post(`/api/collaborate/bankNew`, data)
}
//--------最低提款
export function get_value(params) {
    return request.get(`/api/new_home/get_value`, { params })
}

// -------聚合支付 微信支付 生成二维码图片
export function getCreate_imageByUrl(params) {
    return request.get(`/api/login/create_imageByUrl`, { params })
}


