<template>
    <div class="teamsize">
        <!--团队人数  第一模块-->
        <div class="teamsize-first">
            <!--左侧统计-->
            <div class="teamsize-first-left">
                <!--圆形统计扇形-->
                <div id="groupChart" style="width: 30vw; height: 24.375rem"></div>
                <!--环形统计右侧居中文案-->
                <!-- <div class="teamsize-first-left-div">
          <div class="teamsize-first-left-div-for">
            <div
              class="teamsize-first-left-div-for-list"
              v-for="(item, index) in memberArrayTitle"
              :key="index"
            >
              <div
                class="list-lint"
                :style="{
                  background:
                    index == 0
                      ? '#00A9E4'
                      : index == 1
                      ? '#91CC75'
                      : index == 2
                      ? '#EE6666'
                      : index == 3
                      ? '#F9B63C'
                      : '#5470C6',
                }"
              ></div>
              <div class="list-title">{{ item }}</div>
            </div>
          </div>
        </div> -->
            </div>
            <!--右侧表单-->
            <div class="teamsize-first-right">
                <div class="teamsize-first-right-top">
                    <div class="teamsize-first-right-top-title">团队总人数（人）</div>
                    <div class="teamsize-first-right-top-text">{{ teamObj.total }}</div>
                </div>

                <div class="teamsize-first-right-center">
                    <div class="teamsize-first-right-center-list" v-for="(item, index) in memberList" :key="index">
                        <div class="teamsize-first-right-center-list-num">
                            {{
                        index == 0
                            ? teamObj.general
                            : index == 1
                                ? teamObj.vip
                                : index == 2
                                    ? teamObj.collaborateLeve1
                                    : index == 3
                                        ? teamObj.collaborateLeve2
                                        : index == 4
                                            ? teamObj.collaborateLeve3
                                            : ''
                    }}
                        </div>
                        <div class="teamsize-first-right-center-list-title">
                            {{ item }}(人)
                        </div>
                    </div>
                </div>

                <div class="teamsize-first-right-bottom">
                    <div class="teamsize-first-right-bottom-queer" v-for="(item, index) in 2" :key="index">
                        <div class="right-bottom-queer-title">
                            {{ index == 0 ? '当月新增人数（人）' : '当日新增人数（人）' }}
                        </div>
                        <div class="right-bottom-queer-num">
                            {{ index == 0 ? teamObj.month : teamObj.day }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--团队人数  第二模块-->
        <div class="teamsize-second">
            <div class="teamsize-second-left">
                <div class="teamsize-second-left-title">日新增团队人数</div>
                <div id="newdayChart" style="width: 100%; height: 18.75rem"></div>
            </div>
            <div class="teamsize-second-right">
                <div class="teamsize-second-right-title">月新增团队人数</div>
                <div id="monthnewlyChart" style="width: 100%; height: 18.75rem"></div>
            </div>
        </div>

        <!--公共底部模块-->
        <bottom></bottom>
    </div>
</template>

<script>
import bottom from '../components/bottom.vue'
import { getDateTime } from '../utils/time' // 时间戳转化
import { userStatistics, getCountStatistics } from '../api/index' // 团队管理数据统计表

export default {
    components: {
        bottom,
    },
    data() {
        return {
            memberList: ['普通用户', 'VIP客户', '初级合伙人', '精英合伙人', '事业合伙人'],
            // 第一模块统计环形右侧需要的文案
            memberArrayTitle: [
                '普通用户',
                'VIP客户',
                '初级合伙人',
                '精英合伙人',
                '事业合伙人',
            ],
            // 通过js获取当前年月份 列如 2022-04
            teamsizeValue: '',
            // 团队人数  team对象
            teamObj: {},
            teamObj1: {}
        }
    },
    created() {
        // 通过js获取对应的时间
        this.teamsizeValue = getDateTime('month')
        // 团队管理人数统计表
        this.userStatistics()
        this.getCountStatistics()
    },
    mounted() {
        // 月新增团队人数
        this.monthnewly()
    },
    methods: {
        // 团队人数 统计表
        userStatistics() {
            let params = {}
            userStatistics(params).then((res) => {
                if (res.data.code == 200) {
                    this.teamObj1 = res.data.content
                    let teamgroupObj = res.data.content

                    // 当前月份新增人数数组
                    // 日新增团队人数
                    let teamgroupNewly = res.data.content.dayList
                    // 当前月份时间日期
                    let teamgroupTime = []
                    // 当前月份新增人数新增
                    let teamgroupPeople = []
                    teamgroupNewly.forEach((item, index) => {
                        teamgroupTime.push(item.date.slice(-2))
                        teamgroupPeople.push(item.userNum)
                    })

                    // 查询当前月份日期数组
                    // 月新增团队人数
                    let teamgroupMonthArray = res.data.content.monthList
                    // 当前月份时间日期
                    let teamgroupMonthTime = []
                    // 当前月份新增人数新增
                    let teamgroupMonthPeople = []
                    teamgroupMonthArray.forEach((item, index) => {
                        teamgroupMonthTime.push(item.date.slice(-2))
                        teamgroupMonthPeople.push(item.userNum)
                    })

                    this.$nextTick(() => {
                        // 团队总人数第二模块 左侧统计表
                        this.daynewly(teamgroupTime, teamgroupPeople)
                        // 团队总人数第二模块 右侧统计表
                        this.monthnewly(teamgroupMonthTime, teamgroupMonthPeople)
                    })
                } else {
                    // 退出登陆 清除用户资料
                    localStorage.clear()
                    // 重设路由
                    resetRouter()
                }
            })
        },
        // 团队人数 统计表
        getCountStatistics() {
            let params = {}
            getCountStatistics(params).then((res) => {
                if (res.data.code == 200) {
                    this.teamObj = res.data.content
                    this.teamObj.general = Number(res.data.content.total) - Number(res.data.content.collaborateLeve1) - Number(res.data.content.collaborateLeve2) - Number(res.data.content.collaborateLeve3)
                    let teamgroupObj = this.teamObj


                    this.$nextTick(() => {
                        // 团队总人数的扇形统计表
                        this.group(teamgroupObj)
                    })
                } else {
                    // 退出登陆 清除用户资料
                    localStorage.clear()
                    // 重设路由
                    resetRouter()
                }
            })
        },
        // 圆形扇形统计图
        group(obj) {
            // 基于准备好的dom，初始化echarts实例
            var myChart = this.$echarts.init(document.getElementById('groupChart'))
            // 绘制图表
            myChart.setOption(
                {
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b} : {c} ({d}%)',

                    },
                    legend: {
                        // orient: 'vertical',
                        // x: 'right',
                        // y: 'center',
                        itemGap: 10,
                        bottom: '5%',
                        right: '5%',
                        itemWidth: 12,
                        itemHeight: 12,
                        data: ['普通用户', 'VIP客户', '初级合伙人', '精英合伙人', '事业合伙人'],
                    },
                    calculable: true,
                    color: ['#00A9E4', '#91CC75', '#EE6666', '#F9B63C', '#5470C6'],
                    series: [
                        {
                            name: '用户等级',
                            type: 'pie',
                            radius: ['40%', '60%'],
                            itemStyle: {
                                normal: {
                                    label: {
                                        show: true,
                                    },
                                    labelLine: {
                                        show: true,
                                    },
                                },
                            },
                            label: {
                                normal: {
                                    position: 'outside', // 在内部显示，outseide 是在外部显示
                                    show: true,
                                    // formatter: '{c}',
                                },
                            },
                            data: [
                                { value: obj.general, name: '普通用户' },
                                { value: obj.vip, name: 'VIP客户' },
                                { value: obj.collaborateLeve1, name: '初级合伙人' },
                                { value: obj.collaborateLeve2, name: '精英合伙人' },
                                { value: obj.collaborateLeve3, name: '事业合伙人' },
                            ],
                        },
                    ],
                },
                true
            )
        },
        // 团队人数管理 第二模块左侧数据 日新增团队人数
        daynewly(time, people) {
            // 基于准备好的dom，初始化echarts实例
            var myChart = this.$echarts.init(document.getElementById('newdayChart'))
            // 绘制图表
            myChart.setOption(
                {
                    tooltip: {
                        trigger: 'axis',
                    },
                    legend: {
                        data: ['新增人数'],
                    },
                    color: ['#00A9E4'],
                    calculable: true,
                    xAxis: [
                        {
                            type: 'category',
                            data: time,
                            axisLine: {
                                show: false,
                                // 坐标轴线
                                lineStyle: {
                                    color: '#999999',
                                },
                            },
                            axisTick: {
                                //刻度线
                                show: false,
                                lineStyle: {
                                    color: '#999999',
                                },
                            },
                        },
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            splitLine: {
                                show: false, //不显示分隔线
                            },
                            axisLine: {
                                show: false,
                                // 坐标轴线
                                lineStyle: {
                                    color: '#999999',
                                },
                            },
                            axisTick: {
                                //刻度线
                                show: false,
                                lineStyle: {
                                    color: '#999999',
                                },
                            },
                            minInterval: 1,
                        },
                    ],
                    grid: {
                        left: '8%',
                        right: '4%',
                        top: '60px',
                        bottom: '10%',
                    },
                    series: [
                        {
                            name: '新增人数',
                            barWidth: 6.68,
                            distance: 5, // 距离
                            type: 'line',
                            smooth: true,
                            symbol: 'none',
                            data: people,
                        },
                    ],
                },
                true
            )
        },
        // 团队人数管理 第二模块右侧数据 月新增团队人数
        monthnewly(time, people) {
            // 基于准备好的dom，初始化echarts实例
            var myChart = this.$echarts.init(
                document.getElementById('monthnewlyChart')
            )
            // 绘制图表
            myChart.setOption(
                {
                    tooltip: {
                        trigger: 'axis',
                    },
                    legend: {
                        data: ['新增人数'],
                    },
                    calculable: true,
                    color: ['#29D647'],
                    xAxis: [
                        {
                            type: 'category',
                            data: time,
                            axisLine: {
                                show: false,
                                // 坐标轴线
                                lineStyle: {
                                    color: '#999999',
                                },
                            },
                            axisTick: {
                                //刻度线
                                show: false,
                                lineStyle: {
                                    color: '#999999',
                                },
                            },
                        },
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            splitLine: {
                                show: false, //不显示分隔线
                            },
                            axisLine: {
                                // 坐标轴线
                                lineStyle: {
                                    color: '#999999',
                                },
                            },
                            axisTick: {
                                //刻度线
                                show: false,
                                lineStyle: {
                                    color: '#999999',
                                },
                            },
                            minInterval: 1,
                        },
                    ],
                    grid: {
                        left: '8%',
                        right: '4%',
                        top: '60px',
                        bottom: '10%',
                    },
                    series: [
                        {
                            name: '新增人数',
                            type: 'bar',
                            barWidth: 25,
                            data: people,
                        },
                    ],
                },
                true
            )
        },
    },
}
</script>

<style lang="less" scoped>
.teamsize {
    margin: 20px;

    .teamsize-first {
        background: #fff;
        padding: 10px 20px;
        box-sizing: border-box;
        display: flex;
        // justify-content: center;

        .teamsize-first-left {
            display: flex;

            .teamsize-first-left-div {
                width: 110px;
                height: 390px;
                background: #ffffff;
                display: flex;
                align-items: center;

                .teamsize-first-left-div-for {
                    .teamsize-first-left-div-for-list {
                        display: flex;
                        height: 20px;
                        align-items: center;
                        margin-bottom: 14px;
                        cursor: pointer;

                        .list-lint {
                            width: 14px;
                            height: 10px;
                            border-radius: 2px;
                        }

                        .list-title {
                            margin-left: 8px;
                            height: 20px;
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #222222;
                            line-height: 20px;
                        }
                    }

                    .teamsize-first-left-div-for-list:nth-child(5n + 5) {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .teamsize-first-right {
            width: 490px;
            height: 390px;
            background: #ffffff;
            margin-left: 17%;

            .teamsize-first-right-top {
                margin-top: 56px;
                display: flex;
                height: 30px;
                align-items: center;

                .teamsize-first-right-top-title {
                    font-size: 16px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                }

                .teamsize-first-right-top-text {
                    font-size: 22px;
                    font-family: DINAlternate-Bold, DINAlternate;
                    font-weight: bold;
                    color: #f9b63c;
                }
            }

            .teamsize-first-right-center {
                height: 190px;
                background: rgba(247, 247, 247, 0.5);
                border-radius: 4px;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                padding: 0 45px;
                box-sizing: border-box;
                align-items: center;
                margin-top: 23px;

                .teamsize-first-right-center-list {
                    text-align: center;
                    width: calc(100% / 3);

                    .teamsize-first-right-center-list-num {
                        font-size: 22px;
                        font-family: DINAlternate-Bold, DINAlternate;
                        font-weight: bold;
                        color: #555555;
                    }

                    .teamsize-first-right-center-list-title {
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #888888;
                    }
                }

                .teamsize-first-right-center-list:nth-child(3n + 3) {
                    margin-right: 0;
                }
            }

            .teamsize-first-right-bottom {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                margin-top: 32px;

                .teamsize-first-right-bottom-queer {
                    display: flex;
                    height: 30px;
                    align-items: center;

                    .right-bottom-queer-title {
                        font-size: 16px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #333333;
                    }

                    .right-bottom-queer-num {
                        font-size: 22px;
                        font-family: DINAlternate-Bold, DINAlternate;
                        font-weight: bold;
                        color: #f9b63c;
                    }
                }
            }
        }
    }

    .teamsize-second {
        width: 100%;
        display: flex;
        margin-top: 30px;
        justify-content: space-between;
        margin-bottom: 30px;

        .teamsize-second-left {
            background: #fff;
            width: 50%;

            .teamsize-second-left-title {
                font-size: 16px;
                font-weight: 600;
                color: black;
                padding: 20px;
            }
        }

        .teamsize-second-right {
            margin-left: 20px;
            background: #fff;
            width: 50%;

            .teamsize-second-right-title {
                font-size: 16px;
                font-weight: 600;
                color: black;
                padding: 20px;
            }
        }
    }
}
</style>
